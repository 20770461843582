import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../components/input';
import AjaxButton from '../../../../components/ajaxButton';

import styles from '../../styles/company.module.scss';

const LABELS = {
  ADD_OVERDRAFT: 'Добавление овердрафта',
  LIMIT: 'Лимит по договору',
  ADDITIONAL_LIMIT: 'Дополнительный лимит',
  OVERDRAFT: 'Овердрафт',
  PERIOD: 'Период овердрафта (в днях)',
  ADDITIONAL_OVERDRAFT: 'Дополнительный овердрафт',
  ADDITIONAL_PERIOD: 'Дополнительный период овердрафта (в днях)',
  ADD: 'Добавить',
};

const FIELDS = {
  OVERDRAFT: 'overdraft',
  PERIOD: 'period',
  ADDITIONAL_OVERDRAFT: 'additionalOverdraft',
  ADDITIONAL_PERIOD: 'additionalPeriod',
};

export default class OverdraftForm extends Component {
  static propTypes = {
    company: PropTypes.object,
    onSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    company: {},
  };

  constructor(props) {
    super(props);

    const { Overdraft, OverdraftPeriodInDays, AdditionalOverdraft, AdditionalOverdraftPeriodInDays } = props.company;

    this.state = {
      overdraft: Overdraft,
      period: OverdraftPeriodInDays,
      additionalOverdraft: AdditionalOverdraft,
      additionalPeriod: AdditionalOverdraftPeriodInDays,
      waitingResponse: false,
    };
  }

  onChangeField = (e, field, val) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState(state => ({
      ...state,
      [field]: val,
    }));
  };

  addOverdraft = () => {
    const add = () => {
      this.props.onSave(this.state)
        .catch(() => { this.setState({ waitingResponse: false }); });
    };

    this.setState({ waitingResponse: true }, add);
  };

  render() {
    const {
      overdraft,
      period,
      additionalOverdraft,
      additionalPeriod,
      waitingResponse,
    } = this.state;

    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <div className={ `${styles.row} ${styles.title}` }>{ LABELS.ADD_OVERDRAFT }</div>
        <div className={ styles.dialog_content_wrap }>
          <div className={ styles.dialog_content }>
            <div className={ styles.title_stamp }>{ LABELS.LIMIT }</div>
            <div className={ styles.row }>
              <Input
                field={ FIELDS.OVERDRAFT }
                label={ LABELS.OVERDRAFT }
                onChange={ this.onChangeField }
                value={ overdraft }
              />
            </div>
            <div className={ styles.row }>
              <Input
                field={ FIELDS.PERIOD }
                label={ LABELS.PERIOD }
                onChange={ this.onChangeField }
                value={ period }
              />
            </div>
          </div>
          <div className={ styles.dialog_content }>
            <div className={ styles.title_stamp }>{ LABELS.ADDITIONAL_LIMIT }</div>
            <div className={ styles.row }>
              <Input
                field={ FIELDS.ADDITIONAL_OVERDRAFT }
                label={ LABELS.ADDITIONAL_OVERDRAFT }
                value={ additionalOverdraft }
                onChange={ this.onChangeField }
              />
            </div>
            <div className={ styles.row }>
              <Input
                field={ FIELDS.ADDITIONAL_PERIOD }
                label={ LABELS.ADDITIONAL_PERIOD }
                value={ additionalPeriod }
                onChange={ this.onChangeField }
              />
            </div>
          </div>
        </div>
        <div className={ `${styles.row} ${styles.action}` }>
          <AjaxButton label={ LABELS.ADD } loading={ waitingResponse } onClick={ this.addOverdraft } />
        </div>
      </div>
    );
  }
}
