import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'sw-ui';

import AjaxButton from '../../../../components/ajaxButton';
import Input from '../../../../components/input';

import COMPONENTS from '../../../../bi/constants/components';

import styles from './styles/index.module.css';

const LABELS = {
  TITLE: 'Номер ИСД',
  EDIT: 'Изменить',
  CANCEL: 'Отменить',
  SAVE: 'Сохранить',
};

const maxLength = 7;

class IsdNumber extends Component {
  static propTypes = {
    details: PropTypes.object.isRequired,
    companyService: PropTypes.object.isRequired,
    INPUT_FIELDS: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showEditing: false,
      textInputValid: '',
      waitingResponse: false,
    };
  }

  updateState = () => {
    this.setState({ isd: this.props.details.Isd });
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.details.Isd !== this.props.details.Isd) {
      this.updateState();
    }
  }

  handleToggleEditing = () => {
    this.setState({
      showEditing: !this.state.showEditing,
      isd: this.props.details.Isd,
    });
  };

  handleUpdateIsd = async () => {
    const {
      companyService: { saveDetails },
      details,
    } = this.props;
    const { isd } = this.state;

    const newIsd = isd || null;

    this.setState({ waitingResponse: true });

    await saveDetails({ ...details, Isd: newIsd });

    this.setState({
      textInputValid: '',
      waitingResponse: false,
      showEditing: !this.state.showEditing,
    });
  };

  handleSaveEditing = () => {
    const {
      INPUT_FIELDS: { ISD },
      companyService: { requisitesFieldValidation },
    } = this.props;
    const { isd } = this.state;

    const textInputValid = requisitesFieldValidation(ISD, isd);

    if (textInputValid) {
      return this.setState({ textInputValid });
    }

    return this.handleUpdateIsd();
  };

  handlerChangeIsdInput = (value) => {
    if (value.length > maxLength) return;

    this.setState({ isd: value });
  };

  renderEditingInput = () => {
    const { INPUT_FIELDS: { ISD } } = this.props;
    const { isd, textInputValid, waitingResponse } = this.state;

    return (
      <div className={ styles.row }>
        <Input
          className={ styles.inputIsd }
          field={ ISD }
          value={ isd }
          onChange={ (e, field, value) => this.handlerChangeIsdInput(value) }
          valid={ textInputValid }
        />
        <div className={ styles.row }>
          <div className={ styles.action }>
            <Button
              label={ LABELS.CANCEL }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ this.handleToggleEditing }
            />
          </div>
          <div className={ styles.action }>
            <AjaxButton
              label={ LABELS.SAVE }
              loading={ waitingResponse }
              onClick={ () => this.handleSaveEditing() }
            />
          </div>
        </div>
      </div>
    );
  };

  renderText = () => {
    const { isd } = this.state;

    const contentIsd = isd || '';

    return (
      <div className={ styles.row }>
        <div>
          <label className={ styles.label } >{ LABELS.TITLE }</label>
        </div>
        <div>
          <span>{ contentIsd }</span>
        </div>
        <Button
          label={ LABELS.EDIT }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ this.handleToggleEditing }
          className={ styles['edit-button'] }
        />
      </div>
    );
  };

  renderContent = () => {
    const { showEditing } = this.state;

    return showEditing ? this.renderEditingInput() : this.renderText();
  };

  render() {
    return this.renderContent();
  }
}

export { IsdNumber };
